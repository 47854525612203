<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    {{ id ? '修改' : '新增' }}仪器仪表分类
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="deviceClassInfo" :rules="rules" ref="deviceClassInfo">
                    <!-- <el-form-item label="编号" :label-width="formLabelWidth" prop="id" v-if="state">
                        <el-input v-model="deviceClassInfo.id" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item> -->
                    <el-form-item label="级别" :label-width="formLabelWidth" prop="level">
                        <el-radio :disabled="state" v-model="level" :label=0>一级</el-radio>
                        <el-radio :disabled="state" v-model="level" :label=1>二级</el-radio>
                        <el-radio :disabled="state" v-model="level" :label=2>三级</el-radio>
                    </el-form-item>
                    <el-form-item label="分类编号" :label-width="formLabelWidth" prop="subClassCode" v-if="state">
                        <el-input v-model="deviceClassInfo.subClassCode" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                    <el-form-item label="分类名称" :label-width="formLabelWidth" prop="subClassDesc">
                        <el-input v-model="deviceClassInfo.subClassDesc" autocomplete="off"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="上级分类" :label-width="formLabelWidth" v-if="!(level === 0)">
                        <el-select placeholder="请选择上级分类名称" v-model="deviceClassInfo.superClassCode">
                            <el-option v-for="(item, index) in superClassList" :key="index" :label="item.subClassDesc"
                                :value="item.subClassCode"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="上级分类" :label-width="formLabelWidth" prop="optionValue" v-if="!(level === 0)">
                        <!-- <el-input v-model="formInline.account" placeholder="请输入账号查询"></el-input> -->
                        <el-cascader v-model="deviceClassInfo.optionValue" :options="superClassList" :props="Props"
                            :show-all-levels="false"></el-cascader>
                    </el-form-item>
                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button  class="btn" :disabled="submitFlag" @click="submitForm()">提交</el-button>
                        <el-button @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-card>
    </div>
</template>
<script>
import { getDeviceClassByLevel, getDeviceClass, addDeviceClass, updateDeviceClass } from '@/api/deviceClass.js'
export default {
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            superClassList: [],
            Props: { value: 'subClassCode', label: 'subClassDesc' },
            deviceClassInfo: {
                id: '',
                subClassCode: '',
                subClassDesc: '',
                superClassCode: '',
                level: 0,
                optionValue: null
            },
            level: 0,
            formLabelWidth: '80px',
            rules: {
                level: [{ required: true }],
                subClassDesc: [{ required: true, message: '请输入分类名称' }],
                superClassCode: [{ required: true, message: '请选择上级分类' }]
            },
            submitFlag: false,// 是否提交中,
            state: this.id ? true : false

        }
    },
    mounted() {
        this.id && this.loaddeviceClassInfo()
        this.loadSelect()
    },
    methods: {
        async loadSelect() {
            await getDeviceClassByLevel(this.level).then(res => {
                if (res.code === '000000') {
                    this.superClassList = res.t
                }
            })
        },
        loaddeviceClassInfo() {
            getDeviceClass(this.id).then(res => {
                if (res.code === '000000') {
                    this.deviceClassInfo = res.t
                    this.level = res.t.level
                }
            })
        },
        submitForm() {
            this.$refs.deviceClassInfo.validate((valid) => {
                if (valid) {
                    this.deviceClassInfo.level = this.level
                    this.submitFlag = true
                    if (this.id) {
                        updateDeviceClass(this.deviceClassInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                               
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    } else {
                        addDeviceClass(this.deviceClassInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                                this.$refs.deviceClassInfo.resetFields()
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        handleReset() {
            this.$refs.deviceClassInfo.resetFields()
        }
    },
    watch: {
        level: function () {
            console.log("级别发生改变")
            this.loadSelect()
        }
    }
}

</script>

<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 500px;
    }

    .df {
        text-align: left;
    }

}
</style>